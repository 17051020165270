import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { Form, Button } from "react-bootstrap";
import { SafeHtmlParser } from "../components/SafeHtmlParser"; // Adjust path if needed
const freeEmailDomains = require("free-email-domains");

const BlogCta = ({ heading, body }) => {
	// Formspree hook
	const [formspreeState, formspreeSubmit] = useForm("mknalbrg");

	// Local state for the free-domain error
	const [emailError, setEmailError] = useState("");

	// Handle email blur: check if domain is free
	function handleEmailBlur(event) {
		const emailValue = event.target.value.trim().toLowerCase();
		const domain = emailValue.split("@")[1] || "";
		// If the domain is in the free-email-domains list, show error
		if (freeEmailDomains.includes(domain)) {
			setEmailError("Please use a business email address (not a free domain).");
		} else {
			setEmailError("");
		}
	}

	// Handle final form submission
	async function handleSubmit(e) {
		e.preventDefault();
		// If there's a free-domain error, block submission
		if (emailError) {
			return;
		}
		// Otherwise submit to Formspree
		await formspreeSubmit(e);
	}

	// If user submitted successfully, redirect or show a success message
	if (formspreeState.succeeded) {
		return <div className="d-none">{(window.location = "/thank-you")}</div>;
	}

	return (
		<div
			className="w-100"
			style={{
				borderRadius: "12px",
				backgroundColor: "#fff",
				padding: "1.5rem",
				boxShadow: "0px 1px 13.4px 0px rgba(55, 73, 166, 0.06)",
			}}
		>
			{/* Heading */}
			{heading && (
				<h4 style={{ fontWeight: 700, marginBottom: "0.75rem" }}>
					<SafeHtmlParser htmlContent={heading} />
				</h4>
			)}

			{/* Body */}
			{body && (
				<p style={{ marginBottom: "1.25rem" }}>
					<SafeHtmlParser htmlContent={body} />
				</p>
			)}

			<Form onSubmit={handleSubmit}>
				{/* Website URL */}
				<Form.Group className="mb-3" controlId="websiteUrl">
					<Form.Label className="fw-bold">Your website URL</Form.Label>
					<Form.Control
						type="url"
						name="websiteUrl"
						placeholder="http://"
						required
					/>
					{/* Show any Formspree validation errors for websiteUrl */}
					<ValidationError
						prefix="websiteUrl"
						field="websiteUrl"
						errors={formspreeState.errors}
					/>
				</Form.Group>

				{/* Email */}
				<Form.Group className="mb-3" controlId="email">
					<Form.Label className="fw-bold">Your email</Form.Label>
					<Form.Control
						type="email"
						name="email"
						placeholder="info@acme.com"
						required
						onBlur={handleEmailBlur}
						// Mark as invalid if there's a free-domain error
						isInvalid={!!emailError}
					/>
					{/* Show error from Formspree if any */}
					<ValidationError
						prefix="email"
						field="email"
						errors={formspreeState.errors}
					/>
					{/* Show our free-domain error */}
					{emailError && (
						<Form.Control.Feedback type="invalid">
							{emailError}
						</Form.Control.Feedback>
					)}
				</Form.Group>

				{/* Submit button */}
				<Button
					type="submit"
					disabled={formspreeState.submitting}
					className="w-100 text-white button-reverse-gradient"
				>
					Continue
				</Button>
			</Form>
		</div>
	);
};

export default BlogCta;
